// extracted by mini-css-extract-plugin
export var root = "PlasmicTooltip-module--root--Wsyg0";
export var rootbg_yellow = "PlasmicTooltip-module--rootbg_yellow--si5au";
export var rootbg_orange = "PlasmicTooltip-module--rootbg_orange--2U65N";
export var rootbg_blue = "PlasmicTooltip-module--rootbg_blue--BoTNt";
export var rootbg_red = "PlasmicTooltip-module--rootbg_red--cxjmS";
export var rootbg_purple = "PlasmicTooltip-module--rootbg_purple--SzEDt";
export var rootbg_grey = "PlasmicTooltip-module--rootbg_grey--D9lGY";
export var slotTargetContentbg_mint = "PlasmicTooltip-module--slotTargetContentbg_mint--YWe1A";
export var slotTargetContentbg_yellow = "PlasmicTooltip-module--slotTargetContentbg_yellow--5tzKv";
export var slotTargetContentbg_orange = "PlasmicTooltip-module--slotTargetContentbg_orange--rIOX5";
export var slotTargetContentbg_blue = "PlasmicTooltip-module--slotTargetContentbg_blue--rNzcj";
export var slotTargetContentbg_red = "PlasmicTooltip-module--slotTargetContentbg_red--vnU7C";
export var slotTargetContentbg_purple = "PlasmicTooltip-module--slotTargetContentbg_purple--nT1Ix";
export var slotTargetContentbg_grey = "PlasmicTooltip-module--slotTargetContentbg_grey--JcBBN";
export var text___4Lrap = "PlasmicTooltip-module--text___4Lrap--TxW8c";